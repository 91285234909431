export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Talentos',
    route: 'modelAgentsView',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Catálogos',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Habilidades',
        route: 'abilitiesView',
        icon: 'GitBranchIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Complexiones',
        route: 'complexionsView',
        icon: 'GridIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Destinos',
        route: 'destinationsView',
        icon: 'MapPinIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Lenguajes',
        route: 'languagesView',
        icon: 'GlobeIcon',
        resource: 'Auth',
        action: 'read',
      },
    ],
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    children: [

      {
        title: 'Tipos de modelos',
        route: 'modelTypesView',
        icon: 'GridIcon',
        resource: 'Auth',
        action: 'read',
      },

      {
        title: 'Planes de suscripción',
        route: 'modelAgentSubscriptionPlansView',
        icon: 'GridIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Cupones de suscripción',
        route: 'modelAgentSubscriptionCouponsView',
        icon: 'GridIcon',
        resource: 'Auth',
        action: 'read',
      },

      {
        title: 'Tipos de eventos',
        route: 'eventTypesView',
        icon: 'GridIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Precios',
        route: 'pricesView',
        icon: 'DollarSignIcon',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'Usuarios',
        route: 'usersView',
        icon: 'HomeIcon',
        resource: 'Auth',
        action: 'read',
      },
    ],
  },

]
